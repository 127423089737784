<template>
  <div class="body add-brand-form">
    <el-form
      :model="addForm"
      :rules="rules"
      ref="addForm"
      label-width="82px"
      label-position="left"
      class="demo-form dialog-form"
    >
      <!-- 品牌名称： -->
      <el-form-item label="品牌名称：" prop="name">
        <el-input v-model.trim="addForm.name" placeholder="请输入品牌姓名" :disabled="brandId!==''"></el-input>
      </el-form-item>

      <!-- 所属部门： -->
      <el-form-item label="所属部门：" prop="deptId">
        <companySelect :value="companyId" @getValue="getGroupId" />
      </el-form-item>
      <!--  品牌描述：-->
      <el-form-item label="品牌描述：">
        <el-input v-model.trim="addForm.description" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <!-- 品牌图片： -->
    <p class="from-title">品牌图片：</p>
    <div class="form-area photo-area">
      <!-- <el-upload
              :action="uploadUrl()"
              :headers="getHeader()"
              accept="image/png, image/gif, image/jpg, image/jpeg"
              list-type="picture-card"
              :limit="3"
              :file-list="addForm.photos"
              :before-remove="handleBeforeRemove"
              :on-success="handleAvatarSuccess"
              :class="{ disUoloadSty: isPhotoExceed }"
              ref="upload"
            >
              <i class="el-icon-plus"></i>
      </el-upload>-->
      <el-upload
        action="https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        accept="image/png, image/gif, image/jpg, image/jpeg"
        :limit="3"
        :before-remove="handleBeforeRemove"
        :on-success="handleAvatarSuccess"
        :class="{ disUoloadSty: isPhotoExceed }"
        ref="upload"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </div>
  </div>
</template>
<script>
import {
  checkName,
} from "@/common/utils/index.js";
import {
  insertProdsBrands,
  updateProdsBrands,
  getProdsBrandsById,
} from "@/api/lib/api.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
export default {
  components: {
    companySelect
  },
  props: {
    brandId: {
      type: String
    }
  },
  data () {
    return {
      companyId: null,
      addForm: {
        "deptId": '',
        "description": "",
        "id": '',
        "name": "",
        "picUrl": ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入品牌名",
            trigger: "blur"
          },
          { validator: checkName, trigger: "blur" }
        ],
        deptId: [
          { required: true, message: "请选择企业", trigger: "blur" }
        ],
      },
      isPhotoExceed: false
    };
  },
  methods: {
    /**获取企业id */
    getGroupId (v) {
      this.addForm.deptId = v;
    },
    // 移除照片确认
    handleBeforeRemove (file, fileList) {
      // getProdsBrandsById({ fileName: file.response ? file.response.data : file.url }).then(res => {
      //   if (res.code === 1000) {
      //     this.addForm.photos.forEach((item, index) => {
      //       if (file.response) {
      //         if (item === file.response.data) {
      //           this.addForm.photos.splice(index, 1)
      //         }
      //       } else {
      //         if (item.url === file.url) {
      //           this.addForm.photos.splice(index, 1)
      //         }
      //       }
      //     })
      //   }
      // })
    },
    // 成功添加照片
    handleAvatarSuccess (res, file) {
      // if (res.code === 1000) {
      //   this.addForm.photos.push(res.data)
      // }
    },
    // 上传接口
    uploadUrl () {
      return
    },
    // 上传请求头
    getHeader () {
      return {
        Authorization: localStorage.getItem('token'),
        token: localStorage.getItem('token')
      }
    },
    // 新增/保存品牌
    onSave () {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          let data = {
            name: this.addForm.name,
            deptId: this.addForm.deptId,
            description: this.addForm.description,
            picUrl: "default"
          };
          if (this.brandId === "") {
            insertProdsBrands(data).then(res => {
              if (res.code === 0) {
                this.$message.success("新增成功");
              } else {
                this.$message.error("新增失败");
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          } else {
            data = {
              ...data,
              id: this.addForm.id,
            };
            updateProdsBrands(data).then(res => {
              if (res.code === 0) {
                this.$message.success("修改成功");
              } else {
                this.$message.error("修改失败");
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          }
        }
      });
    },
  },
  created () {
    let role = []
    if (this.brandId) {
      getProdsBrandsById(this.brandId).then(res => {
        if (res.code === 0) {
          this.addForm = { ...res.data };
          delete this.addForm.createBy
          delete this.addForm.createTime
          delete this.addForm.status
          delete this.addForm.updateBy
          delete this.addForm.updateTime
          this.companyId = res.data.deptId
        }
      });
    }
  },
  mounted () { },
  watch: {
    'addForm.photos' (val) {
      if (val.length > 2) {
        this.isPhotoExceed = true
      } else {
        this.isPhotoExceed = false
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.add-brand-form {
  @include themify() {
    .from-title {
      font-size: 14px;
      color: themed("n7");
      margin: 10px 0 10px;
    }
    .photo-area {
      padding-bottom: 16px;
    }
  }
}
</style>
